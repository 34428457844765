<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <b-card >
          <ScheduleManage />
        </b-card>
      </div>
    </div>
  </div>
</template>
<script>
import {
  BCard,
  BMediaBody,
  BMediaAside,
  BMedia,
  BFormFile,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BCardBody,
} from "bootstrap-vue";
import _ from "lodash";
import ScheduleManage from "../partials/ScheduleManage.vue";

export default {
  components: {
    ScheduleManage,
    BCardBody,
    BCard,
    BMedia,
    BFormFile,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BMediaAside,
    BMediaBody,
  },
};
</script>
